<template>
  <PotokPage
    :title="
      isMobile ? 'стратегия инвестирования' : 'стратегия автоинвестирования'
    "
    class="auto-invest-page"
  >
    <template #titleRight>
      <PotokButton
        theme="linkIconUpload"
        size="link"
        text="что такое автоинвест"
        class="strategy-desc-btn"
        @click="onClickShowDescription"
      >
        что такое автоинвест
      </PotokButton>
    </template>

    <template v-if="loading || !isUserLoaded || !loadingInited">
      <Loader class="icon-loading__mt-60" />
    </template>

    <template v-if="!loading && isUserLoaded && loadingInited">
      <div class="strategy-container">
        <div
          v-for="strategy in strategies"
          :key="strategy.name"
          class="strategy"
          :class="{
            'grey-strategy':
              strategy.serverName ===
                constants.strategiesServerNames.personalBorrowerDistribution ||
              strategy.serverName ===
                constants.strategiesServerNames.defaultBorrowerDistribution,
          }"
          @click="chooseStrategy(strategy.serverName)"
        >
          <StrategyItem
            :strategy="strategy"
            :loading="loading"
            :selected-strategy="selectedStrategy"
            :diversification-options="{
              isEnhancedDiversification: selectedEnhancedDiversification,
              showModalDiversification,
              isSelectedStrategyInSecondMarket,
            }"
            @connect-strategy="onClickConnectStrategy"
            @show-condition="onClickShowCondition"
            @show-future-rate-desc="onClickShowFutureRateDesc"
          />
        </div>
      </div>
      <div class="footer-desc">
        <div style="max-width: 650px">
          стратегии Без риска, (Альфа) Консервативная, (Альфа) Базовая,
          Агрессивная не требуют подтверждения квалификации инвестора для
          инвестирования более 600 000 рублей в год
        </div>
        <button
          type="button"
          class="button button-grey-outline button-auto-config"
          :disabled="loading || isAutoInvestSwitchedOff"
          @click="onClickDisableStrategy()"
        >
          отключить автоинвестирование
        </button>
      </div>
    </template>

    <DynamicStrategiesModals
      v-if="store.state.features.general?.additionalStepForEnableStrategies?.enabled"
      :selected-strategy="selectedForDynamicModalsStrategy"
      :is-show="isShowDynamicModals"
      @close="handleCloseDynamicModals"
      @finish="handleFinishDymamicModals"
    />

    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="loadingConnectStrategy"
      :request="connectStrategy"
      :confirm-function="onClickConnectStrategyConfirm"
      :error-message="confirmErrorMessage"
      :confirm-message="confirmMessage"
      :title="'Подписание документа'"
      @close="isShowModalConfirm = false"
    />

    <ModalFull
      v-if="message"
      :title="messageTitle"
      class="modal-full-autoinvest"
      @close="onCloseMessage"
    >
      <template #content>
        <div v-html="message" />
      </template>
    </ModalFull>

    <ModalConfirmDiversification
      v-if="isShowModalConfirmDiversification"
      :options="optionsDiversification"
      @close="isShowModalConfirmDiversification = false"
      @change="onChangeDiversification"
    />
  </PotokPage>
</template>
<script setup>
import { computed, watch } from 'vue';

import { useRouter } from 'vue-router';
import { useStateStore } from '@/store/stateStore';
import server from '@/server';
import { Loader, PotokButton, PotokPage } from 'potok-uikit';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

import constants from '@/constants';

import ModalConfirmDiversification from '@/components/AutoInvest/ModalConfirmDiversification.vue';
import { diversificationUtils } from '@/components/AutoInvest/diversificationUtils.js';
import StrategyItem from '@/components/AutoInvest/StrategyItem.vue';
import { autoInvestUtils } from '@/components/AutoInvest/autoInvestUtils.js';
import detectDevice from '@/composables/detectDeviceComposable';
import DynamicStrategiesModals from '@/components/_generic/DynamicStrategiesModals.vue';

const store = useStateStore();
const router = useRouter();
const { isMobile } = detectDevice();

const {
  isShowModalConfirmDiversification,
  optionsDiversification,
  showModalDiversification,
  isSelectedStrategyInSecondMarket,
} = diversificationUtils();

const {
  loading,
  loadingConnectStrategy,
  loadingInited,
  settingsId,
  confirmErrorMessage,
  confirmMessage,
  message,
  messageTitle,
  selectedStrategy,
  strategies,
  connectStrategyCallback,
  choosedStrategyForConnect,
  isShowModalConfirm,
  selectedEnhancedDiversification,
  isShowDynamicModals,

  getStrategies,
  getBonusFromBalance,
  chooseStrategy,
  getAutoInvestSettingsAsync,
  connectStrategy,
  onCloseMessage,
  onClickShowCondition,
  onClickShowFutureRateDesc,
  selectedForDynamicModalsStrategy,
  newCompanyId,
} = autoInvestUtils();

const isUserLoaded = computed(() => store.isUserLoaded);
const isAutoInvestSwitchedOff = computed(
  () => !selectedStrategy.value || selectedStrategy.value.strategy === 'manual',
);

const onClickShowDescription = () => {
  message.value =
    'Автоинвестирование — это алгоритм, который автоматически осуществляет от имени инвестора акцепт инвестиционных предложений заемщиков, и, таким образом, позволяет инвестировать ваши свободные деньги на платформе в займы, прошедшие тщательный отбор, и обеспечивает их оптимальное распределение в соответствии с выбранной стратегией';
  messageTitle.value = 'что такое автоинвестирование?';
};

const handleCloseDynamicModals = () => {
  isShowDynamicModals.value = false;
};

const handleFinishDymamicModals = () => {
  isShowDynamicModals.value = false;
  handleSuccessDymamicModals(selectedForDynamicModalsStrategy.value);
};

const onClickConnectStrategy = (strategy) => {
  selectedForDynamicModalsStrategy.value = strategy;
  if (store.state.features.general?.additionalStepForEnableStrategies?.enabled) {
    isShowDynamicModals.value = true;
  } else {
    handleFinishDymamicModals();
  }
};

const handleSuccessDymamicModals = (strategy) => {
  if (strategy.serverName === 'personal_borrower_distribution') {
    return onClickChangePersonal();
  }
  if (strategy.isSecondaryMarketStrategy) {
    return showModalDiversification({
      selectedStrategy: strategy,
      isEnhancedDiversification: false,
      isConnectButton: true,
    });
  }
  choosedStrategyForConnect.value = strategy;
  return connectStrategy();
};

const onClickConnectStrategyConfirm = ({ code }) => {
  confirmErrorMessage.value = null;
  loadingConnectStrategy.value = true;
  const query = {
    code,
  };
  return server.putCompanyAutoInvestSettings
    .send(query, {
      params: { id: newCompanyId.value, settingsId: settingsId.value },
    })
    .pipe(
      onPutAutoInvestSettingsSuccess.bind(this),
      onPutAutoInvestSettingsError.bind(this),
    )
    .exec();
};
const onPutAutoInvestSettingsSuccess = (obj) => {
  const data = obj.data || {};
  loadingConnectStrategy.value = false;
  if (data.isSuccessful) {
    getAutoInvestSettingsAsync(true);
    isShowModalConfirm.value = false;

    let optionsObj = {};
    if (newCompanyId.value) {
      let callback = () => {
        store.setSelectedCompany(newCompanyId.value);
      };
      optionsObj.callback = callback;
    }

    store.fetchCompanies(optionsObj);
    router.push({
      name: 'main',
    });
  } else if (data.message) {
    confirmErrorMessage.value = data.message;
  } else {
    confirmErrorMessage.value =
      'Неизвестная ошибка. Обратитесь в тех. поддержку';
  }
};
const onPutAutoInvestSettingsError = () => {
  loadingConnectStrategy.value = false;
};

const onClickChangePersonal = () => {
  router.push({
    name: 'autoInvestPersonal',
  });
};

/**
 * options = {
 *  isConnectButton: Boolean - через какую кнопку было вызвано модальное окно
 *  isClickCancel: Boolean - какая кнопка была нажата на модальном окне
 *  isClickConfirm: Boolean - какая кнопка была нажата на модальном окне
 *  continueConnectStrategy: Boolean - признак продолжения подключение стратегии
 *  selectedStrategy: Object - выбранная стратегия подключения
 * }
 */
const onChangeDiversification = (options) => {
  // ситуация когда пользователь нажал отмена на модальном окне диверсификации
  if (options.isClickCancel) {
    if (!options.continueConnectStrategy) {
      isShowModalConfirmDiversification.value = false;
    }
    if (options.continueConnectStrategy) {
      selectedEnhancedDiversification.value = false;
      connectStrategyCallback.value = () => {
        isShowModalConfirmDiversification.value = false;
      };
      choosedStrategyForConnect.value = options.selectedStrategy;
      connectStrategy();
    }
  }
  // ситуация когда пользователь нажал подтверждаю на модальном окне диверсификации
  if (options.isClickConfirm) {
    if (options.isConnectButton) {
      selectedEnhancedDiversification.value = true;
    }
    if (!options.isConnectButton) {
      selectedEnhancedDiversification.value = selectedStrategy.value
        .isEnhancedDiversification
        ? false
        : true;
    }
    connectStrategyCallback.value = () => {
      isShowModalConfirmDiversification.value = false;
    };
    choosedStrategyForConnect.value = options.selectedStrategy;

    connectStrategy();
  }
};

const onClickDisableStrategy = () => {
  connectStrategyCallback.value = null;
  choosedStrategyForConnect.value = {
    confirmMessage: 'Подтвердите отключение автоинвестирования',
    serverName: 'manual',
  };
  connectStrategy();
};

watch(
  () => {
    return store.selectedCompanyId;
  },
  (value) => {
    getAutoInvestSettingsAsync();
    getStrategies();
    if (
      value &&
      store.state.features?.general?.remainingBalanceInterestPromo?.enabled
    ) {
      getBonusFromBalance();
    }
  },
);

watch(
  () => {
    return store.state.loaders.isFeaturesLoading;
  },
  (value) => {
    if (
      !value &&
      store.selectedCompanyId &&
      store.state.features.general?.remainingBalanceInterestPromo?.enabled
    ) {
      getBonusFromBalance();
    }
  },
);

getStrategies();
if (store.selectedCompanyId) {
  getAutoInvestSettingsAsync();
}
</script>

<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.strategy {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.strategy-grey {
  background: $background;
}

.auto-config-header {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: $size_767) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}

:deep(.main-text) {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: $deep_dive;
  text-transform: lowercase;

  @media (max-width: $size_767) {
    font-size: 20px;
    line-height: 26px;
  }
}

.auto-invest-page .strategy-desc-btn {
  position: relative;
  top: 2px;
  margin-right: 5px;

  @media (max-width: $size_767) {
    margin-top: 10px;
    width: auto;
    top: 0;
  }
}

.chosen-strategy-checkbox {
  display: none;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  background: #fff;

  @media (max-width: $size_767) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

:deep(.auto-invest-bonus-from-balance) {
  @media (max-width: $size_767) {
    position: absolute;
    top: 20px;
    right: 14px;
  }
}

:deep(.custom-checkbox-row .check) {
  background-color: #fff;
  margin-right: 4px;
}

.auto-config-title {
  font-size: 30px;

  @media (max-width: $size_767) {
    font-size: 20px;
  }
}

:deep(.strategy-button-connect) {
  margin-right: 10px;
  background: $breakwater;
  border: 1px solid $breakwater;
  color: #fff;

  &:hover {
    background: none;
    color: $breakwater;
  }

  @media (max-width: $size_767) {
    width: 125px;
  }
}

.grey-strategy {
  background: #e8ebee;
}

:deep(.modal-full-autoinvest) {
  ul {
    padding-left: 20px;
  }

  .future-rate-formule-first {
    height: 32px;
    width: 139px;
    display: block;
    background: url('@img/future-rate-formule1.png');
    background-size: cover;
    border-radius: 6px;
    margin-top: 20px;
  }

  .future-rate-formule-second {
    height: 32px;
    width: 177px;
    display: block;
    background: url('@img/future-rate-formule2.png');
    background-size: cover;
    border-radius: 6px;
    margin-top: 30px;
  }

  .future-rate-low-text {
    font-size: 9px;
    position: relative;
    top: 2px;
  }

  .future-rate-normal-text {
    color: $deep_dive !important;
  }

  .future-rate-formule-desc {
    margin-top: 16px;
  }

  .future-rate-margin-top {
    margin-top: 25px;
  }

  .future-rate-modal-bottom {
    margin-top: 40px;
  }
}

.footer-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: $grey;
  flex-wrap: wrap;

  button {
    @media (max-width: $size_767) {
      margin-top: 30px;
    }
  }
}
</style>
