<template>
  <div class="potok-chip" @click.prevent>
    <PotokTooltip
      v-if="slots.dropdown"
    >
      <button
        :style="{
          color: props.colorText,
          background: props.colorBackground,
        }"
        class="potok-chip-btn"
      >
        <PotokImg
          v-if="props.imageSrc"
          :height="props.imageHeight"
          :width="props.imageWidth"
          :src="props.imageSrc"
          class="mr-1"
        />
        {{ props.text }}
      </button>
      <template #popper>
        <slot name="dropdown" />
      </template>
    </PotokTooltip>

    <button
      v-else
      :style="{
        color: props.colorText,
        background: props.colorBackground,
      }"
      class="potok-chip-btn"
    >
      <PotokImg
        v-if="props.imageSrc"
        :height="props.imageHeight"
        :width="props.imageWidth"
        :src="props.imageSrc"
        class="mr-1"
      />
      {{ props.text }}
    </button>
  </div>
</template>

<script setup>
import { useSlots } from 'vue';
import { PotokTooltip } from 'potok-uikit';
import PotokImg from '@/components/_generic/PotokImg.vue';

const slots = useSlots();

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  imageSrc: {
    type: String,
    default: '',
  },
  imageHeight: {
    type: Number,
    default: 10,
  },
  imageWidth: {
    type: Number,
    default: 10,
  },
  colorText: {
    type: String,
    required: true,
  },
  colorBackground: {
    type: String,
    required: true,
  },
  isDropdownOnHover: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss">
.potok-chip-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 8px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
  padding: 4px 8px;
  border-radius: 6px;
}
</style>
