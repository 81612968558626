<template>
  <div class="login_wrapper">
    <HeaderUnauth @click="currentMode = 'inputPhone'" />
    <component
      :is="currentComponent"
      class="login-component"
      :raw-phone="rawPhone"
      :component-step="componentStep"
      :params="params"
      @change-mode="onChangeMode"
      @change-phone="onChangePhone"
    />

    <PotokTooltip
      v-if="currentMode === 'inputPassword' && isShowChangePasswordHint"
      data-test="login-page-password-hint"
    >
      <div
        ref="triggerIcon"
        class="title-icon"
      >
        <i class="icon_info" />
      </div>
      <template #popper>
        если вы регистрировались, через Альфа-Инвестиции, чтобы войти нажмите «Забыли пароль?»
      </template>
    </PotokTooltip>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { PotokTooltip } from 'potok-uikit';
import { filter, map, merge, unique } from '@/utils/commonUtils/utils';
import tracker from '@/tracker';
import { useStateStore } from '@/store/stateStore';
import LoginInputPhone from './Login/LoginPhone.vue';
import LoginInputPassword from './Login/LoginPassword.vue';
import LoginInputRegister from './Login/LoginRegister.vue';
import LoginInputRecover from './Login/LoginRecover.vue';
import HeaderUnauth from '@/components/Header/HeaderUnauth.vue';

const store = useStateStore();
const route = useRoute();
const router = useRouter();

const rawPhone = ref(null);
const currentMode = ref('inputPhone');
const componentStep = ref(null);
const params = ref(null);

const triggerIcon = ref(null);
const isShowChangePasswordHint = ref(false);

const currentComponent = computed(() => {
  if (currentMode.value === 'inputPhone') {
    return LoginInputPhone;
  }
  if (currentMode.value === 'inputPassword') {
    return LoginInputPassword;
  }
  if (currentMode.value === 'inputRegister') {
    return LoginInputRegister;
  }
  if (currentMode.value === 'inputRecovery') {
    return LoginInputRecover;
  }
  return null;
});

onMounted(async () => {
  // Роутинг отделен от всего остально и выполянется ассинхронно
  // так как нам нужно поймать все utm метки поэтому ждем загрузки роутинга и только потом
  // продолжаем выполнение логики дальше
  await router.isReady();
  init();
});

const init = () => {
  grabAndMergeAndCommitUtms();
  tracker.queue(tracker.commands.SEND, 'PageView');
  const login = route.query.login || store.state.session.login;
  if (login) {
    rawPhone.value = login.slice(1);
  }
};

const grabAndMergeAndCommitUtms = () => {
  const objNewUtms = filter(route.query, (_, key) => key.startsWith('utm_'));
  const arrNewUtms = map(objNewUtms, (v, k) => [k, v]);
  const arrOldUtms = store.state.user.utm;
  const utm = unique(merge(arrOldUtms, arrNewUtms));
  const user = { ...store.state.user, utm };
  store.set({ user });
};

const triggerDropdown = () => {
  triggerIcon.value.click();
  setTimeout(() => {
    const contentElement = document.querySelector('.v-popper__popper');
    if (contentElement) {
      const elementHiddenAttribute = contentElement.getAttribute('aria-hidden');

      if (elementHiddenAttribute === 'false') {
        triggerIcon.value.click();
      }
    }
  }, 10000);
};
const onChangeMode = (options) => {
  if (options.component) {
    currentMode.value = options.component;
    isShowChangePasswordHint.value = options.isShowChangePasswordHint;
    if (
      currentMode.value === 'inputPassword' &&
      isShowChangePasswordHint.value
    ) {
      nextTick(() => {
        triggerDropdown();
      })
    }
  }
  if (options.step) {
    componentStep.value = options.step;
  }
  if (options.params) {
    params.value = options.params;
  }
};

const onChangePhone = (phone) => {
  rawPhone.value = phone;
};
</script>
<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.version-text {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  opacity: 0;
}

.login-component {
  padding-top: 72px;
}

.login_logo {
  position: absolute;
  margin-top: 30px;
  margin-left: 53px;

  @media (max-width: $size_991) {
    margin-left: 15px;
  }
}

.login_wrapper {
  height: 100%;
  min-height: 600px;
  background-color: white;
  position: relative;
}

:deep(.login_logo) {
  margin-top: 30px;
  margin-left: 53px;

  @media (max-width: $size_991) {
    margin-left: 16px;
    margin-top: 20px;
  }
}

:deep(.login_subtitle) {
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $deep_dive;
  opacity: 0.9;

  @media (max-width: $size_991) {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  span {
    white-space: nowrap;
  }
}

:deep(.login_input_wrapper) {
  width: 340px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.v-popper) {
  position: absolute;
  top: 35px;
  right: 50px;

  @media (max-width: $size_991) {
    top: 22px;
    right: 18px;
  }
}

:deep(#otpCode) {
  width: 340px;

  @media (max-width: $size_991) {
    min-width: 288px;
    width: 100%;
  }
}

:deep(.button-password) {
  height: 60px;
  width: 340px;
  margin-top: 24px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.button-continue) {
  height: 60px;
  width: 340px;
  margin-top: 24px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.button-repeat) {
  color: $grey;
  margin-top: 20px;
}

:deep(.countdown) {
  color: $grey;
  margin-top: 20px;
  text-align: center;
}

:deep(.text_tips) {
  color: $grey;
  margin-top: 10px;
}

:deep(.button-auth) {
  height: 60px;
}

:deep(input) {
  color: $deep_dive;
  height: 60px;
  border: 0;
  border-radius: 8px;
  background: #f2f4f8;
  margin: 0 auto;

  @media (max-width: $size_991) {
    width: 100%;
  }
}
</style>
