<template>
  <div class="new-investments-sell-btn-status-container">
    <button
      class="new-investments-sell-btn-status potok-text-uppercase"
      :class="{
        'confirmed-status-btn': props.status === 'confirmed',
        'approved-status-btn': props.status === 'approved',
        'canceled-or-rejected-status-btn':
          props.status === 'canceled' || props.status === 'rejected',
        'completed-status-btn': props.status === 'completed',
      }"
    >
      {{ text }}
    </button>

    <PotokTooltip
      v-if="props.comment"
      placement="bottom-end"
    >
      <PotokImg
        :src="imageConstants.attention"
        height="14"
        width="14"
        class="ml-3"
        @click.stop
      />
      <template #popper>
        {{ props.comment }}
      </template>
    </PotokTooltip>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { PotokTooltip, PotokImg } from 'potok-uikit';
import imageConstants from '@/constants/imageConstants';

const props = defineProps(['status', 'comment']);

const text = computed(() => {
  switch (props.status) {
    case 'confirmed':
      return 'активна';
    case 'approved':
      return 'обрабатывается';
    case 'canceled':
      return 'отменена';
    case 'rejected':
      return 'отклонена';
    case 'created':
      return 'создана';
    case 'completed':
      return 'реализовано';
    default:
      return 'не известный';
  }
});
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.new-investments-sell-btn-status-container {
  display: flex;
  align-items: center;
}

.new-investments-sell-btn-status {
  padding: 4px 8px;
  border-radius: 6px;
  border: none;
  cursor: initial;
}

.new-investments-sell-btn-attention {
  height: 16px;
  width: 16px;
}

.confirmed-status-btn {
  background-color: rgb(4 204 217 / 10%);
  color: $tiffany;
}

.approved-status-btn {
  background-color: rgb(47 128 237 / 10%);
  color: $blue;
}

.canceled-or-rejected-status-btn {
  background-color: rgb(238 61 72 / 10%);
  color: $red;
}

.completed-status-btn {
  background-color: rgb(255 107 0 / 10%);
  color: rgb(255 107 0 / 100%);
}
</style>
