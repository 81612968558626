<template>
  <div class="project_details__wrapper">
    <horizontal-scroll v-if="!isTablet">
      <div class="project_details__wrapper_left">
        <PotokChip
          v-if="props.item.minInvestmentAmount"
          :text="`от ${formatCurrency(props.item.minInvestmentAmount || 100000, 0)} ₽`"
          color-text="#10273D"
          color-background="#F2F4F8"
          :image-src="imageConstants.attentionDark"
          :image-height="10"
          :image-width="10"
          class="project__tag"
        >
          <template #dropdown>
            <span>
              в этот займ можно инвестировать от
              {{
                formatCurrency(props.item.minInvestmentAmount || 100000, 0)
              }}
              рублей
            </span>
          </template>
        </PotokChip>

        <template v-if="props.item.loanType">
          <label-loan-type :loan-type="props.item.loanType" />
        </template>

        <template v-if="props.item.isPotokFinanceBorrower || props.item.isPotokHoldingBorrower">
          <PotokChip
            text="ФИКС"
            color-text="#173B57"
            color-background="#E8EBEE"
            class="project__tag"
          >
            <template #dropdown>
              <span>займы, где риски на себя берет Поток, а вы получаете фиксированную доходность</span>
            </template>
          </PotokChip>
        </template>

        <template v-if="!(props.item.isPotokFinanceBorrower || props.item.isPotokHoldingBorrower)">
          <PotokChip
            v-if="props.item.rating === 'A'"
            text="ПОНИЖЕННЫЙ РИСК"
            color-text="#173B57"
            color-background="#E8EBEE"
            :image-src="imageConstants.loanChips.lowRisk"
            class="project__tag"
          >
            <template #dropdown>
              <span>Пониженный риск</span>
            </template>
          </PotokChip>

          <PotokChip
            v-if="props.item.isManualInvestExplicit"
            text="РИСК"
            color-text="rgb(238 61 72)"
            color-background="rgba(238, 61, 72, 0.1)"
            class="project__tag"
          >
            <template #dropdown>
              <div>
                Автоинвест по умолчанию не вкладывает средства в займы с
                повышенным уровнем риска, но они доступны для ручного
                инвестирования и для инвестирования через пользовательские
                настройки автоинвеста. Более высокая ставка отражает нашу
                оценку уровня риска данного заемщика.
                <br />
                <a
                  class="link-more"
                  href="https://investor.potok.digital/faq#12"
                  target="_blank"
                  @click.stop
                >
                  Подробнее
                </a>
              </div>
            </template>
          </PotokChip>
        </template>

        <div
          v-if="props.item.mmRefundedLoansCount > 0"
          class="project__tag project-experianced-borrower"
          @click.prevent
        >
          <PotokTooltip>
            <button class="info-icon-hint">
              <span class="project__tag-icon">опытный</span>
            </button>
            <template #popper>
              <span>Опытный заёмщик — имеет как минимум один погашенный заём</span>
            </template>
          </PotokTooltip>
        </div>
      </div>
    </horizontal-scroll>

    <div v-else>
      <div
        class="project_details__wrapper_left project_details__wrapper_left_tablet"
      >
        <template v-if="props.item.loanType">
          <label-loan-type :loan-type="props.item.loanType" />
        </template>

        <template v-if="props.item.isPotokFinanceBorrower || props.item.isPotokHoldingBorrower">
          <PotokChip
            text="ФИКС"
            color-text="#173B57"
            color-background="#E8EBEE"
            class="project__tag"
          >
            <template #dropdown>
              <span>займы, где риски на себя берет Поток, а вы получаете фиксированную доходность</span>
            </template>
          </PotokChip>
        </template>

        <template v-if="!(props.item.isPotokFinanceBorrower || props.item.isPotokHoldingBorrower)">
          <PotokChip
            v-if="props.item.rating === 'A'"
            text="ПОНИЖЕННЫЙ РИСК"
            color-text="#173B57"
            color-background="#E8EBEE"
            :image-src="imageConstants.loanChips.lowRisk"
            class="project__tag"
          >
            <template #dropdown>
              <span>Пониженный риск</span>
            </template>
          </PotokChip>

          <PotokChip
            v-if="props.item.isManualInvestExplicit"
            text="РИСК"
            color-text="rgb(238 61 72)"
            color-background="rgba(238, 61, 72, 0.1)"
            class="project__tag"
          >
            <template #dropdown>
              <div>
                Автоинвест по умолчанию не вкладывает средства в займы с
                повышенным уровнем риска, но они доступны для ручного
                инвестирования и для инвестирования через пользовательские
                настройки автоинвеста. Более высокая ставка отражает нашу
                оценку уровня риска данного заемщика.
                <br />
                <a
                  class="link-more"
                  href="https://investor.potok.digital/faq#12"
                  target="_blank"
                  @click.stop
                >
                  Подробнее
                </a>
              </div>
            </template>
          </PotokChip>
        </template>

        <div
          v-if="props.item.mmRefundedLoansCount > 0"
          class="project__tag project-experianced-borrower"
          @click.prevent
        >
          <PotokTooltip>
            <button class="info-icon-hint">
              <span class="project__tag-icon">опытный</span>
            </button>
            <template #popper>
              <span>Опытный заёмщик — имеет как минимум один погашенный заём</span>
            </template>
          </PotokTooltip>
        </div>
      </div>
    </div>
    <div
      v-if="!isViewedItem(props.item.id)"
      class="project_details__wrapper_right"
      @click.prevent
    >
      <PotokTooltip>
        <button class="info-icon-hint">
          <div class="project__icon_views"></div>
        </button>
        <template #popper>
          <span>Не просмотрено</span>
        </template>
      </PotokTooltip>
    </div>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils/commonUtils/libUtils';
import HorizontalScroll from '@/components/_generic/HorizontalScroll.vue';
import LabelLoanType from '@/components/_generic/tags/LabelLoanType.vue';
import { isViewedItem } from '../../../utils/localStorage/viewedProjects';
import { PotokTooltip } from 'potok-uikit';

import detectDevice from '@/composables/detectDeviceComposable';
import PotokChip from '@/components/_generic/PotokChip.vue';
import imageConstants from '@/constants/imageConstants';

const props = defineProps(['item']);
const { isTablet } = detectDevice();
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.project_details__wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $size_991) {
    align-items: flex-start;
  }
}

.project_details__wrapper_left {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 25px;
}

.project_details__wrapper_left_tablet {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .project__tag {
    margin-left: 0;
    margin-right: 6px;
    margin-bottom: 8px;

    &:first-child {
      margin-left: 0;
      margin-right: 6px;
    }
  }
}

.project_details__wrapper_right {
  display: flex;
  align-items: center;
  margin-left: 10px;

  @media (max-width: $size_991) {
    margin-top: 10px;
  }
}

.project__icon_views {
  width: 8px;
  height: 8px;
  background: #04ccd9;
  border-radius: 50%;
  margin: 0;
}

.project__tag {
  &:first-child {
    margin-left: 0;
  }

  @media (max-width: $size_767) {
    margin: 0 0 0 6px;
  }
}

.low-risk {
  .project__tag-icon {
    background: #e8ebee;
    color: $breakwater;
  }
}

.low-risk .low-risk-text {
  display: flex;
}

.low-risk-icon {
  margin-right: 4px;
  display: block;
  height: 10px;
  width: 10px;
  background: url('@img/shield-tick.svg');
}

.project__tag-icon {
  @media (max-width: $size_767) {
    margin: 0 5px;
  }
}
</style>
