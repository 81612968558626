<template>
  <PotokTooltip>
    <button class="info-icon-hint project__tag collateral">
      <PotokImg
        :src="imageConstants.attention"
        :height="isTablet ? 20 : 14"
        :width="isTablet ? 20 : 14"
        class="mr-2"
      />
    </button>
    <template #popper>
      <div>
        <slot />
      </div>
    </template>
  </PotokTooltip>
</template>
<script setup>
import imageConstants from '@/constants/imageConstants';
import detectDevice from '@/composables/detectDeviceComposable';
import { PotokTooltip } from 'potok-uikit';

const { isTablet } = detectDevice();
</script>
<style lang="scss" scoped></style>
