import LoansPage from '@/pages/LoansPage.vue';
import LoanPage from '@/pages/LoanPage.vue';
import LoanInvestment from '@/pages/Loan/LoanInvestment.vue';
import InvestmentPage from '@/pages/InvestmentPage.vue';
import ReferenceLoansPage from '@/pages/ReferenceLoansPage.vue';

export default [
  {
    path: '/loans',
    name: 'loans',
    components: {
      content: LoansPage,
    },
    meta: {
      title: 'Новые займы',
    },
  },
  {
    path: '/loans/:id',
    name: 'loan',
    components: {
      content: LoanPage,
    },
    meta: {
      title: 'Страница займа',
    },
  },
  {
    path: '/loans/:id/investment',
    name: 'loanInvestment',
    components: {
      content: LoanInvestment,
    },
  },
  {
    path: '/investment/:id/',
    name: 'investment',
    components: {
      content: InvestmentPage,
    },
    meta: {
      title: 'Страница инвестиции',
    },
  },
  {
    path: '/loans-reference',
    name: 'loansReference',
    components: {
      content: ReferenceLoansPage,
    },
    meta: {
      title: 'Страница эталонных займов',
    },
  },
];
