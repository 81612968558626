<template>
  <div
    v-if="store.state.user.name && store.state.user.surname && isShow"
    :class="{ 'mb-4': isShow }"
  >
    <PassportCheckRejected
      v-if="
        store.state.user.passportProcessingStatus ===
        constants.passportStatus.REJECTED
      "
      @open-rejected-passport="emits('openRejectedPassport')"
    />
    <PassportCheckEmpty
      v-if="
        store.state.user.passportProcessingStatus ===
        constants.passportStatus.EMPTY
      "
      @open-rejected-passport="emits('openRejectedPassport')"
    />
    <PassportCheckPending
      v-if="
        store.state.user.passportProcessingStatus ===
        constants.passportStatus.PENDING
      "
    />
    <InvestmentNonApproved
      v-if="store.selectedCompany?.investmentApproved === false && store.selectedCompany?.investmentApprovedMessage"
      :message="store.selectedCompany?.investmentApprovedMessage"
    />
  </div>
</template>
<script setup>
import { useStateStore } from '@/store/stateStore';

import constants from '@/constants';
import PassportCheckRejected from '@/components/_generic/messages/PassportCheck/PassportCheckRejected.vue';
import PassportCheckEmpty from '@/components/_generic/messages/PassportCheck/PassportCheckEmpty.vue';
import PassportCheckPending from '@/components/_generic/messages/PassportCheck/PassportCheckPending.vue';
import InvestmentNonApproved from '@/components/_generic/messages/InvestmentNonApproved.vue';
import { computed } from 'vue';

const emits = defineEmits(['openRejectedPassport']);

const store = useStateStore();

const isShow = computed(() => {
  return (
    store.state.user.passportProcessingStatus ===
      constants.passportStatus.REJECTED ||
    store.state.user.passportProcessingStatus ===
      constants.passportStatus.EMPTY ||
    store.state.user.passportProcessingStatus ===
      constants.passportStatus.PENDING ||
    (store.selectedCompany?.investmentApproved === false && store.selectedCompany?.investmentApprovedMessage)
  );
});
</script>
