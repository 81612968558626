<template>
  <div class="dynamic-modals">
    <PotokModal
      v-if="props.isShow && currentModal"
      :title="currentModal.title"
      size="fit"
      @close="close"
    >
      <template #content>
        <div
          class="dynamic-modals-content potok-text-body-2"
          v-html="currentModal.text"
        />
      </template>

      <template #actions>
        <div class="dynamic-modals-actions">
          <PotokButton :text="currentModal.continueButton" @click="nextModal" />
          <PotokButton
            theme="tertiary"
            class="dynamic-modals-cancel"
            :text="currentModal.cancelButton"
            @click="close"
          />
        </div>
      </template>
    </PotokModal>
  </div>
</template>

<script setup>
import { PotokModal, PotokButton } from 'potok-uikit';
import { computed, watch } from 'vue';
import { ref } from 'vue';
import server from '@/server';

const emits = defineEmits(['finish', 'close', 'update-loading']);
const props = defineProps({
  selectedStrategy: {
    type: [Object, null],
    default: () => {},
  },
  isShow: {
    type: Boolean,
    default: false,
  },
});

const modals = ref(null);
const currentModalIndex = ref(0);

const currentModalsList = computed(() => {
  return modals.value[props.selectedStrategy?.serverName];
});

const currentModal = computed(() => {
  if (!modals.value || !currentModalsList.value) return;

  return currentModalsList.value[currentModalIndex.value];
});

const nextModal = () => {
  const maxListIndex = currentModalsList.value.length - 1;

  if (currentModalIndex.value === maxListIndex) {
    finish();
    return;
  }

  currentModalIndex.value += 1;
};

const close = () => {
  currentModalIndex.value = 0;
  emits('close');
};

const finish = () => {
  currentModalIndex.value = 0;
  emits('finish');
};

const getFeaturesDynamicStrategiesModals = () => {
  return server.getFeaturesDynamicStrategiesModals
    .send()
    .pipe(onGetFeaturesDynamicStrategiesModalsSuccess, () => {
      emits('update-loading', false);
    })
    .exec();
};

const onGetFeaturesDynamicStrategiesModalsSuccess = ({ data }) => {
  modals.value = data;
  emits('update-loading', false);
};

getFeaturesDynamicStrategiesModals();

watch(
  () => props.isShow,
  () => {
    if (props.isShow && !currentModalsList.value) {
      finish();
    }
  },
);
</script>

<style scoped lang="scss">
@use '../../scss/variables.scss' as *;

.dynamic-modals-content {
  max-width: 300px;
}

.dynamic-modals-actions {
  display: flex;
  justify-content: center;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.dynamic-modals-cancel {
  margin-left: 10px;

  @media (max-width: $size_767) {
    margin-top: 10px;
    margin-left: 0;
  }
}
</style>
