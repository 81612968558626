export default {
  logo: 'img/logo.svg',
  closeGreyRounded: 'img/icons/close-rounded.svg',
  closeGrey: 'img/icons/close-grey.svg',
  closeWhite: 'img/close-white.svg',
  arrowBack: 'img/icons/arrow-back.svg',
  arrowDown: 'img/icons/arrow-down.svg',
  arrowDownTiffany: 'img/icons/arrow-down-tiffany.svg',
  arrowUp: 'img/icons/arrow-up.svg',
  arrowUpTiffany: 'img/icons/arrow-up-tiffany.svg',
  sellInvestmentsIcon: 'img/icons/investments/sell-investments.svg',
  circlePerson: 'img/icons/profile-circle.svg',
  pencil: 'img/icons/pencil-gray.svg',
  arrowRightDeepdiveLong: 'img/icons/arrow-right-deepdive-long.svg',
  arrowRightTiffany: 'img/icons/arrow-right-tiffany.svg',
  clock: 'img/icons/clock.svg',
  entrepreneur: 'img/icons/entrepreneur.svg',
  company: 'img/icons/company.svg',
  qualificationFilesAdd: 'img/icons/qualification/qualification-files-add.svg',
  attention: 'img/icons/attention/attention.svg',
  attentionDark: 'img/icons/attentionDark.svg',
  bonusFromBalance: 'img/icons/bonus-from-balance.svg',
  bonusFromBalanceGrey: 'img/icons/bonus-from-balance-grey.svg',
  arrowRightDeepdive: 'img/icons/arrow-right-deepdive.svg',
  stepDoneTiffany: 'img/icons/step-done-tiffany.svg',
  gosuslugiIcon: 'img/icons/gosuslugi.png',
  menuBoard: 'img/icons/menu-board.svg',
  acceptCircle: 'img/icons/accept-circle.svg',
  document: 'img/icons/document.svg',
  clearCircle: 'img/icons/clear-circle.svg',
  analytics: {
    redCircleArrayDown: 'img/icons/analytics/assets/red-circle-array-down.svg',
    discountCircle: 'img/icons/analytics/assets/discount-circle.svg',
    discountCircleRed: 'img/icons/analytics/assets/discount-circle-red.svg',
    returns: 'img/icons/analytics/assets/returns.svg',
    health: 'img/icons/analytics/assets/health.svg',
  },
  loanChips: {
    lowRisk: 'img/shield-tick.svg',
  },
  loans: {
    bannerPotokFinance: {
      array: 'img/icons/banners/loans-potok-finance/banner-pf-array.svg',
      calendar: 'img/icons/banners/loans-potok-finance/banner-pf-calendar.svg',
      verify: 'img/icons/banners/loans-potok-finance/banner-pf-verify.svg',
    },
    bannerPotokHolding: {
      checkMarkInCircleTiffany:
        'img/icons/banners/loans-holding-banner/checkMarkInCircleTiffany.svg',
      flashTiffany: 'img/icons/banners/loans-holding-banner/flashTiffany.svg',
    },
    loansHeader: {
      rowsVertical: 'img/icons/two-vertical-rows.svg',
      fourSquares: 'img/icons/four-squares.svg',
    },
  },
  settings: {
    personal: 'img/icons/settings/personal.svg',
    investors: 'img/icons/settings/investors.svg',
    notification: 'img/icons/settings/notifications.svg',
    security: 'img/icons/settings/security.svg',
  },
};
