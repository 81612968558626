<template>
  <PotokTable :columns="columns" :rows="props.rows">
    <template #body="props">
      <PotokTableRow
        hover
        class="loans-first-table-row"
        @click="toLoanPage(props.row.id)"
      >
        <PotokTableCell>
          <div class="table-info-container">
            <LoansBorrowerTypeIcon :type="getBorrowerType(props.row.borrower.typeId)" />

            <div class="loans-first-table-info ml-4">
              <div class="loans-first-table-info-top mb-1">
                <LoansItemRating
                  v-if="props.row.borrower.modelBucket"
                  :rating="props.row.borrower.modelBucket"
                />
              </div>

              <span class="text-no-transform">{{ props.row.borrower.shortName }}</span>
            </div>
          </div>
        </PotokTableCell>

        <PotokTableCell>
          {{ formatCurrency(props.row.availableAmount, 0) }} ₽
        </PotokTableCell>

        <PotokTableCell>
          {{ getRateText(props.row.loanRate, props.row.irr) }}
        </PotokTableCell>
      </PotokTableRow>
    </template>
  </PotokTable>

  <PaginationDesktop
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :total-items-count="props.total"
    :items-pluralize-array="['займа','займов','займов']"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import PotokTable from '@/components/_generic/table/PotokTable/PotokTable.vue';
import PotokTableCell from '@/components/_generic/table/PotokTable/PotokTableCell.vue';
import PotokTableRow from '@/components/_generic/table/PotokTable/PotokTableRow.vue';
import PaginationDesktop from '@/components/_generic/pagination/paginationDesktop.vue';

import { createRateText } from '@/utils/strategies/strategiesUtils';
import {
  formatCurrency,
} from '@/utils/commonUtils/utils';

import constants from '@/constants';
import LoansBorrowerTypeIcon from '@/components/Loans/LoansBorrowerTypeIcon.vue';
import LoansItemRating from '@/components/Loans/LoansItemRating.vue';

const router = useRouter();

const props = defineProps(['rows', 'total', 'currentPage', 'selectedPageRange']);
const emits = defineEmits(['fetchList']);

const selectedPageRange = ref(props.selectedPageRange);
const currentPage = ref(props.currentPage);

const columns = [
  {
    label: 'заемщик',
  },
  {
    label: 'доступно для продажи',
  },
  {
    label: 'ставка',
    dropdownMessage: 'номинальная / эффективная',
  },
];

const getRateText = (rate, irr) => {
  let text = `${createRateText(rate)}`

  if (irr) {
    text += ` / ${createRateText(props.row.irr)}`
  }

  text += '%';

  return text;
};

const getBorrowerType = (typeId) => {
  if (typeId === constants.COMPANY_TYPES_ENTREPRENEUR) return 'ENTREPRENEUR';
  return 'COMPANY';
}

const toLoanPage = (id) => {
  router.push({ name: 'loan', params: { id } });
};

const fetchList = () => {
  emits('fetchList', {
    selectedPageRange: selectedPageRange.value,
    offset: selectedPageRange.value * (currentPage.value - 1),
  });
};

watch(currentPage, fetchList);
watch(selectedPageRange, fetchList);
</script>

<style lang="scss" scoped>
.table-info-container {
  display: flex;
  align-items: center;
}

.loans-first-table-info {
  display: flex;
  flex-direction: column;
}

.loans-first-table-info-top {
  display: flex;
  align-items: center;
}

.loans-first-table-goal {
  display: flex;
  align-items: center;
}

.loans-first-table-goal-text {
  display: initial;
  height: fit-content;
}

.loans-first-table-goal .loans-first-table-btn {
  opacity: 0;
}

.loans-first-table-row {
  &:hover {
    .loans-first-table-goal-text {
      display: none;
    }

    .loans-first-table-btn {
      opacity: 1;
    }
  }
}
</style>
