<template>
  <div class="new-investments-sell-btn-status-container">
    <button
      class="new-investments-sell-btn-status potok-text-uppercase"
      :class="{
        'sold-status-btn': props.status === 'sold',
        'approved-status-btn': props.status === 'approved',
        'canceled-or-rejected-status-btn':
          props.status === 'canceled' || props.status === 'rejected',
        'waiting-status-btn': props.status === 'waiting',
      }"
    >
      {{ text }}
    </button>

    <PotokTooltip
      v-if="props.comment"
      placement="bottom-end"
      defaultIconClass="ml-3"
    >
      <template #popper>
        {{ props.comment }}
      </template>
    </PotokTooltip>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { PotokTooltip } from 'potok-uikit';

const props = defineProps(['status', 'comment']);

const text = computed(() => {
  switch (props.status) {
    case 'sold':
      return 'продана';
    case 'approved':
      return 'обрабатывается';
    case 'canceled':
      return 'отменена';
    default:
      return 'ждет обработки';
  }
});
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.new-investments-sell-btn-status-container {
  display: flex;
  align-items: center;
}

.new-investments-sell-btn-status {
  padding: 4px 8px;
  border-radius: 6px;
  border: none;
  cursor: initial;
}

.new-investments-sell-btn-attention {
  height: 16px;
  width: 16px;
}

.sold-status-btn {
  background-color: rgb(4 204 217 / 10%);
  color: $tiffany;
}

.approved-status-btn {
  background-color: rgb(47 128 237 / 10%);
  color: $blue;
}

.canceled-or-rejected-status-btn {
  background-color: rgb(238 61 72 / 10%);
  color: $red;
}

.waiting-status-btn {
  background-color: rgb(255 107 0 / 10%);
  color: rgb(255 107 0 / 100%);
}
</style>
