<template>
  <div class="borrower-rating-tiles">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="{
        'tiles-item-colored': item.colored,
        'tiles-item-colored-opacity': (props.isComponent && item.colored), 
        'tiles-item-small': props.isSmall,
      }"
      class="borrower-rating-tiles-item"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  rating: {
    type: String,
    default: '0/5',
  },
  isComponent: {
    type: Boolean,
    default: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  }
});

const items = computed(() => {
  const maxRating = props.rating[props.rating.length - 1];
  const currentRating = props.rating[0];

  const finalItems = Array.from({ length: maxRating }, (v, i) => {
    return {
      colored: i < currentRating,
    };
  });

  return finalItems;
});
</script>

<style lang="scss">
@use '../../../../scss/variables.scss' as *;

.borrower-rating-tiles {
  display: flex;
}

.borrower-rating-tiles-item {
  height: 20px;
  width: 76px;
  border-radius: 5px;
  margin-right: 7px;
  background-color: $dividers;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: $size_767) {
    height: 14px;
    width: 19%;
    border-radius: 2px;
  }
}

.tiles-item-colored {
  background-color: $breakwater;
}

.tiles-item-colored-small {
  background-color: $breakwater;
  opacity: 0.5;
}

.tiles-item-colored-opacity {
  opacity: 0.6;
}

.tiles-item-small {
  width: 32px;
  height: 10px;
  border-radius: 2px;

  @media (max-width: $size_767) {
    width: 19%;
  }
}
</style>
