<template>
  <div class="loans-item-rating-container">
    <PotokTooltip
      :placement="'bottom'"
      :triggers="['hover']"
    >
      <div class="loans-item-rating">
        <div
          v-for="(bucketItem, i) in bucketItems"
          :key="i"
          class="loans-item-rating-bucket"
          :class="{ 'loans-item-rating-bucket-colored': bucketItem.colored }"
        />
      </div>

      <template #popper>
        рейтинг заемщика — внутренняя оценка <br>
        вероятности дефолта на основе финансовых <br>
        показателей и статистических данных
      </template>
    </PotokTooltip>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { PotokTooltip } from 'potok-uikit';

const props = defineProps(['rating']);

const bucketItems = computed(() => {
  const bucketValue = props.rating;

  const maxItems = bucketValue[bucketValue.length - 1];
  const coloredItems = bucketValue[0];

  const finalItems = Array.from({ length: maxItems }, (v, i) => {
    return {
      colored: i < coloredItems,
    };
  });

  return finalItems;

  // return [{ colored: true },{ colored: true },{ colored: true },{ colored: true },{ colored: false },]
});
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loans-item-rating-container {
  display: flex;
}

.loans-item-rating {
  display: flex;
}

.loans-item-rating-bucket {
  width: 10px;
  height: 6px;
  margin-right: 2px;
  border-radius: 1px;
  background: $input;
}

.loans-item-rating-bucket-colored {
  background: $breakwater;
}
</style>