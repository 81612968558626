<template>
  <div class="balances_active">
    <div class="balances_active_wrapper">
      <div>
        <div class="content_title">
          <div style="display: flex; flex-direction: column">

            <PotokTooltip>
              <div class="potok-text-uppercase">Ваши активы</div>
              <template #popper>
                Сумма основного долга по всем вашим Инвестициям за вычетом
                дефолтов.<br />
                Авто - {{ formatCurrency(activeBalanceAuto, 0) }}&nbsp;₽
                <br />
                Ручные -
                {{ formatCurrency(activeBalanceManual, 0) }}&nbsp;₽
              </template>
            </PotokTooltip>

            <MoneyValue
              :value="balance"
              :rounding="false"
              class="potok-text-h2 mt-2"
            />
            <div
              v-if="accountNumber"
              class="potok-text-body-1 potok-color-deepdive account_number"
            >
              <span>ЛС {{ accountNumber }}</span>
            </div>
          </div>
        </div>
        <div class="content_block_items" style="margin-top: 70px">
          <div class="item_row">
            <div class="item_title potok-text-body-2 potok-color-deepdive">
              <PotokTooltip>
                <div class="title-icon">активные инвестиции</div>
                <template #popper>
                  Сумма основного долга по всем вашим Инвестициям за вычетом дефолтов.
                </template>
              </PotokTooltip>
            </div>
            <MoneyValue
              :value="activeBalance"
              :rounding="false"
              class="potok-text-body-1 mt-1"
            />
          </div>

          <PotokDivider margin-y="12" />

          <div class="item_row">
            <div class="item_title potok-text-body-2 potok-color-deepdive">
              <PotokTooltip>
                <div class="title-icon">денежные средства</div>
                <template #popper>
                  Сумма денежных средств в статусе: Свободно, Копилка и Резерв.
                </template>
              </PotokTooltip>
            </div>
            <MoneyValue
              :value="money"
              :rounding="false"
              class="potok-text-body-1 mt-1"
            />
          </div>
        </div>
      </div>
      <div>
        <router-link
          :to="{ name: 'analyticsHistoryOperation' }"
          class="button button-secondary-outline button-balances"
        >
          история операций
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { formatCurrency } from '@/utils/commonUtils/utils';
import { useStateStore } from '@/store/stateStore';
import { PotokTooltip } from 'potok-uikit';
import MoneyValue from '@/components/_generic/MoneyValue.vue';

const store = useStateStore();

const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const accountNumber = computed(() => {
  if (selectedCompany.value) {
    return selectedCompany.value.investorAccountNumber;
  }
  return null;
});
const activeBalance = computed(() => {
  return store.getActiveBalance;
});
const activeBalanceAuto = computed(() => {
  return store.getActiveBalanceAuto;
});
const activeBalanceManual = computed(() => {
  return store.getActiveBalanceManual;
});
const balance = computed(() => {
  return store.getBalance;
});

const money = computed(() => {
  return freeBalance.value + cashboxRealAmount.value + reserved.value;
});
const freeBalance = computed(() => {
  return store.getFreeBalance;
});
const cashboxRealAmount = computed(() => {
  return store.getCashboxRealAmount;
});
const reserved = computed(() => {
  return store.getReserved;
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.balances_active {
  height: 100%;
  position: relative;
}

.button-balances {
  position: absolute;
  bottom: 10px;
}

.balances_active_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.account_number {
  color: $grey;
  margin-top: 5px;
  text-transform: none;
}

.content_block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.dashboard_block {
  .dashboard_block_content {
    .balances_active {
      .item_row {
        margin: 0;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
